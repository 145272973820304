@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

body{
	background: #222939;
font-family: 'Montserrat', sans-serif !important;
padding: 0;
margin: 0;
}

.body, h1, h2, h3, h4, p{
	font-family: 'Montserrat', sans-serif !important;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

button{
	outline:none;
}

.width-100 {
	width: 100% !important;
}


/*5 Column Style Start*/
.col-xs-5th-1, .col-xs-5th-2, .col-xs-5th-3, .col-xs-5th-4 {
	float: left;
  }
  
  .col-xs-5th-5 {
	float: left;
	width: 100%;
  }
  
  .col-xs-5th-4 {
	width: 80%;
  }
  
  .col-xs-5th-3 {
	width: 60%;
  }
  
  .col-xs-5th-2 {
	width: 40%;
  }
  
  .col-xs-5th-1 {
	width: 20%;
  }
  
  .col-xs-5th-pull-5 {
	right: 100%;
  }
  
  .col-xs-5th-pull-4 {
	right: 80%;
  }
  
  .col-xs-5th-pull-3 {
	right: 60%;
  }
  
  .col-xs-5th-pull-2 {
	right: 40%;
  }
  
  .col-xs-5th-pull-1 {
	right: 20%;
  }
  
  .col-xs-5th-pull-0 {
	right: auto;
  }
  
  .col-xs-5th-push-5 {
	left: 100%;
  }
  
  .col-xs-5th-push-4 {
	left: 80%;
  }
  
  .col-xs-5th-push-3 {
	left: 60%;
  }
  
  .col-xs-5th-push-2 {
	left: 40%;
  }
  
  .col-xs-5th-push-1 {
	left: 20%;
  }
  
  .col-xs-5th-push-0 {
	left: auto;
  }
  
  .col-xs-5th-offset-5 {
	margin-left: 100%;
  }
  
  .col-xs-5th-offset-4 {
	margin-left: 80%;
  }
  
  .col-xs-5th-offset-3 {
	margin-left: 60%;
  }
  
  .col-xs-5th-offset-2 {
	margin-left: 40%;
  }
  
  .col-xs-5th-offset-1 {
	margin-left: 20%;
  }
  
  .col-xs-5th-offset-0 {
	margin-left: 0%;
  }
  
  @media (min-width: 768px) {
	.col-sm-5th-1, .col-sm-5th-2, .col-sm-5th-3, .col-sm-5th-4 {
	  float: left;
	}
  
	.col-sm-5th-5 {
	  float: left;
	  width: 100%;
	}
  
	.col-sm-5th-4 {
	  width: 80%;
	}
  
	.col-sm-5th-3 {
	  width: 60%;
	}
  
	.col-sm-5th-2 {
	  width: 40%;
	}
  
	.col-sm-5th-1 {
	  width: 20%;
	}
  
	.col-sm-5th-pull-5 {
	  right: 100%;
	}
  
	.col-sm-5th-pull-4 {
	  right: 80%;
	}
  
	.col-sm-5th-pull-3 {
	  right: 60%;
	}
  
	.col-sm-5th-pull-2 {
	  right: 40%;
	}
  
	.col-sm-5th-pull-1 {
	  right: 20%;
	}
  
	.col-sm-5th-pull-0 {
	  right: auto;
	}
  
	.col-sm-5th-push-5 {
	  left: 100%;
	}
  
	.col-sm-5th-push-4 {
	  left: 80%;
	}
  
	.col-sm-5th-push-3 {
	  left: 60%;
	}
  
	.col-sm-5th-push-2 {
	  left: 40%;
	}
  
	.col-sm-5th-push-1 {
	  left: 20%;
	}
  
	.col-sm-5th-push-0 {
	  left: auto;
	}
  
	.col-sm-5th-offset-5 {
	  margin-left: 100%;
	}
  
	.col-sm-5th-offset-4 {
	  margin-left: 80%;
	}
  
	.col-sm-5th-offset-3 {
	  margin-left: 60%;
	}
  
	.col-sm-5th-offset-2 {
	  margin-left: 40%;
	}
  
	.col-sm-5th-offset-1 {
	  margin-left: 20%;
	}
  
	.col-sm-5th-offset-0 {
	  margin-left: 0%;
	}
  }
  @media (min-width: 992px) {
	.col-md-5th-1, .col-md-5th-2, .col-md-5th-3, .col-md-5th-4 {
	  float: left;
	  position: relative;
      padding-right: 15px;
      padding-left: 15px;
	}
  
	.col-md-5th-5 {
	  float: left;
	  width: 100%;
	}
  
	.col-md-5th-4 {
	  width: 80%;
	}
  
	.col-md-5th-3 {
	  width: 60%;
	}
  
	.col-md-5th-2 {
	  width: 40%;
	}
  
	.col-md-5th-1 {
	  width: 20%;
	}
  
	.col-md-5th-pull-5 {
	  right: 100%;
	}
  
	.col-md-5th-pull-4 {
	  right: 80%;
	}
  
	.col-md-5th-pull-3 {
	  right: 60%;
	}
  
	.col-md-5th-pull-2 {
	  right: 40%;
	}
  
	.col-md-5th-pull-1 {
	  right: 20%;
	}
  
	.col-md-5th-pull-0 {
	  right: auto;
	}
  
	.col-md-5th-push-5 {
	  left: 100%;
	}
  
	.col-md-5th-push-4 {
	  left: 80%;
	}
  
	.col-md-5th-push-3 {
	  left: 60%;
	}
  
	.col-md-5th-push-2 {
	  left: 40%;
	}
  
	.col-md-5th-push-1 {
	  left: 20%;
	}
  
	.col-md-5th-push-0 {
	  left: auto;
	}
  
	.col-md-5th-offset-5 {
	  margin-left: 100%;
	}
  
	.col-md-5th-offset-4 {
	  margin-left: 80%;
	}
  
	.col-md-5th-offset-3 {
	  margin-left: 60%;
	}
  
	.col-md-5th-offset-2 {
	  margin-left: 40%;
	}
  
	.col-md-5th-offset-1 {
	  margin-left: 20%;
	}
  
	.col-md-5th-offset-0 {
	  margin-left: 0%;
	}
  }
  @media (min-width: 1200px) {
	.col-lg-5th-1, .col-lg-5th-2, .col-lg-5th-3, .col-lg-5th-4 {
	  float: left;
	}
  
	.col-lg-5th-5 {
	  float: left;
	  width: 100%;
	}
  
	.col-lg-5th-4 {
	  width: 80%;
	}
  
	.col-lg-5th-3 {
	  width: 60%;
	}
  
	.col-lg-5th-2 {
	  width: 40%;
	}
  
	.col-lg-5th-1 {
	  width: 20%;
	}
  
	.col-lg-5th-pull-5 {
	  right: 100%;
	}
  
	.col-lg-5th-pull-4 {
	  right: 80%;
	}
  
	.col-lg-5th-pull-3 {
	  right: 60%;
	}
  
	.col-lg-5th-pull-2 {
	  right: 40%;
	}
  
	.col-lg-5th-pull-1 {
	  right: 20%;
	}
  
	.col-lg-5th-pull-0 {
	  right: auto;
	}
  
	.col-lg-5th-push-5 {
	  left: 100%;
	}
  
	.col-lg-5th-push-4 {
	  left: 80%;
	}
  
	.col-lg-5th-push-3 {
	  left: 60%;
	}
  
	.col-lg-5th-push-2 {
	  left: 40%;
	}
  
	.col-lg-5th-push-1 {
	  left: 20%;
	}
  
	.col-lg-5th-push-0 {
	  left: auto;
	}
  
	.col-lg-5th-offset-5 {
	  margin-left: 100%;
	}
  
	.col-lg-5th-offset-4 {
	  margin-left: 80%;
	}
  
	.col-lg-5th-offset-3 {
	  margin-left: 60%;
	}
  
	.col-lg-5th-offset-2 {
	  margin-left: 40%;
	}
  
	.col-lg-5th-offset-1 {
	  margin-left: 20%;
	}
  
	.col-lg-5th-offset-0 {
	  margin-left: 0%;
	}
  }
/*5 Column Style End*/  

.logo {
	/* background-image: linear-gradient(to right, #804488 , #ec3a71); */
	padding: 10px;
	width: 140px !important;
}

.img-logo{
	/*width:85px;*/
	width: 85px;
  /* padding: 0px; */
  margin-left: 60px;
}

.img-responsive{
	width:100%;
}

.no-padding{
	width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
} 

.header-top{
	padding-right: 0px;
	padding-left: 0px;
	align-items: initial !important;
}

.ml-auto, .mx-auto {
    margin-left: 0 !important;
}

.navbar {
    padding: 0 !important;
}

.nav-link {
    display: block;
    padding: 1rem 1rem;
}

.navbar-nav{
	width:100%;
}

.navbar-nav .nav-item{
	/*width: 66.66% !important;*/
	text-align: center;
}

.left-col{
	background: #0c101b;
	height: -webkit-fill-available;
	width: 52px !important;
	margin-top: 51px !important;
	position: fixed;
	top: 0;
	display: none;
}

.right-col{
	padding:0 !important;
	display: inline;
	width: 100%;
  /*margin-left: 52px;*/
}

.left-icons{
	/*padding: 15px 17px 15px 17px;*/
	height: 50px;
  width: 52px;
}

.left-icons.border-top{
	border-top: 1px solid #232732 !important;
}

.row-content{
	margin-left: 0px !important;
	margin-right: 0px !important; 
}

.audio-list, .preferences, .category{
	margin-top: 40px;
}

.audio-list h4.block-title{
	font-family: 'Raleway', sans-serif !important;
	color: #ffffff;
	font-weight: 800 !important;
	text-transform: capitalize;
	letter-spacing: .03em;
  margin-bottom: 12px;
}

.footer{
	margin-top: 50px;
	border-top: 1px solid #232732 ;
}

.preference-content h5.preference-title {
    vertical-align: middle;
    display: table-cell;
    color: #fff;
    text-align: center;
    background: rgba(1, 1, 1, 0.5);
    font-weight: 400 !important;
}
.btn-play{
	color: #ffffff !important;
	cursor: pointer;
	font-weight: 700;
	background: #ec3a71;
	padding: 0px 26px;
	border-radius: 3px;
	height: 35px;
}
.preferences h2.heading, .category h2.heading{
	margin-top: 42px;
	color: #fff;
	font-size: 48px;
	padding-bottom: 20px;
  border-bottom: 1px solid #0c101b;
}

.preference-box, .category .media{
	margin-top: 30px;
	overflow: hidden;
}

.preference-box .preference-data{
	position: relative;
}

.preference-content{
	position: absolute;
    top: 0;
    display: table;
    vertical-align: middle;
    height: 100%;
    width: 100%;
}

.preference-content h5.preference-title{
	vertical-align: middle;
	display: table-cell;
	color:#fff;
	text-align: center;
	background: rgba(1, 1, 1, 0.5);
	font-weight: 400 !important;
}

.preference-content.bg1 {
	background: rgba(140, 151, 197, 0.6);
}

.preference-content.bg2 {
	background: rgba(196, 140, 197, 0.6);
}

.preference-content.bg3 {
	background: rgba(140, 197, 156, 0.6);
}

.preference-content.bg4 {
	background: rgba(197, 140, 140, 0.6);
}

.preference-content.bg5 {
	background: rgba(197, 140, 164, 0.6);
}

.preference-content.bg6 {
	background: rgba(140, 196, 197, 0.6);
}

.preference-content.bg7 {
	background: rgba(142, 197, 140, 0.6);
}

.margin-top-100{
	margin-top: 100px !important;
}

h2.heading{
    color: #fff;
    font-size: 48px;
    padding-bottom: 20px;
}

p.policy{
	color:#ffffff;
	font-size: 12px;
	padding: 20px 5px;
}

.guest{
	width: 80%;
	margin: auto;
	bottom: 5%;
	position: absolute;
	left: 15%;
}

.guest .guest-link{
	color:#ed3a74 !important;
	font-size: 13px;
	text-decoration: underline !important;
}

.login-content{
	position: absolute;
	bottom: 0;
	padding: 20px;
	background-image: linear-gradient(#22293900, #222939);
}

.login-content h3{
	font-size:20px;
	font-weight: 400 !important;
}

.login-content h5{
	font-size:64px;
	font-weight: 600 !important;
	color: #ffffff;
}

.my-list .table th{
	border-top: none;
	border-bottom: 1px solid #0c101b;
	color: #99999b;
}

.my-list .table td{
	vertical-align: middle;
	padding: 10px !important;
	border-bottom: 1px solid #44464a;
	border-top: none;
	color: #b3b3b3;
}

.my-list .table td.title{
	color:#ffffff !important;
}

.left-icons a{
	padding: 2px 26px 29px 26px;
	background-size: 18px 18px !important;
}

/* #myListLink{
	background: url(../img/icons/preferences.png) center no-repeat;
} */

#myListLink:hover, #myListLink.active{
	background: url(../img/icons/preferences-white.png) center no-repeat !important;
	background-color: #ed3a72 !important;
	background-size: 18px 18px !important;
}

#filterLink{
	background: url(../img/icons/filter.png) center no-repeat;
}

#filterLink:hover, #filterLink.active{
	background: url(../img/icons/filter-white.png) center no-repeat !important;
	background-color: #ed3a72 !important;
	background-size: 18px 18px !important;
}

#settingsLink{
	background: url(../img/icons/settings.png) center no-repeat;
}

#settingsLink:hover, #settingsLink.active{
	background: url(../img/icons/settings-white.png) center no-repeat !important;
	background-color: #ed3a72 !important;
	background-size: 18px 18px !important;
}

#profileLink{
	background: url(../img/icons/profile-icon.png) center no-repeat;
}

#profileLink:hover, #profileLink.active{
	background: url(../img/icons/profile-icon-white.png) center no-repeat !important;
	background-color: #ed3a72 !important;
	background-size: 18px 18px !important;
}

a{outline:0;}


.mobile-song-title{
	display: none;
	}
	@media only screen and (max-width:800px){
	.jp-backward{
	display: none!important;
	}
	.mobile-song-title{
	display: block!important;
	}
	.jp-forward{
	display: none!important;
	}
	.jp-volume-controls {
	position: absolute;
	top: 24px;
	right: -15px!important;
	left: unset;
	width: 104px!important;
	}
	.mobile-song-title{
	background: #111215 ;
	padding: 0px 15px;
	margin: 0;
	color: #fff;
	text-align: center;
	margin-top: -1px;
	font-size: 12px;
	}
	#mob_song_artist{
	font-weight: 400;
	font-size: .72em;
	}
	.jp-details .jp-title,.jp-details .jp-artist{
	display: none;
	}
	.jp-details {
	width: 1%!important;
	position: absolute;
	background: transparent;
	display: block !important;
	margin-top: 15px;
	margin-left: 5%;
	display: inherit;
	}
	.space{
	display: none;
	}
	.jp-audio .jp-controls {
	width:100%!important;
	padding-left: 34%!important;
	}
	.jp-next, .jp-next:focus {
    /* background: url(img/icons/forward-white.png) no-repeat; */
    margin-left: 15px!important;
}
	}
	

	/* .cus-poster{
		-webkit-animation: rotation 2s infinite linear;
	

	}

	@-webkit-keyframes rotation {
	from {
	-webkit-transform: rotate(0deg);
	}
	to {
	-webkit-transform: rotate(359deg);
	}
	}
	.body,h1,h2,h3,h4,p{
	font-family: 'Muli', sans-serif;
	} */

a.audio{
	text-decoration: none;
}

.series .slick-slide .audio-detail, .slick-slide .series .audio-detail{
	/*width: 100% !important;*/
	width: 200px !important;
}

.audio-detail{
	background: rgba(1,1,1, 0.5);
	position: absolute;
	bottom: 6px;
	color: #ffffff;
	width: 140px;
	height: 40px;
	border-radius: 0 0 5px 5px;
}

.audio-detail .audio-assets{
	display: inline-block;
	padding: 5px;
}

.audio-detail .language{
	font-size: 10px;
}


.audio-detail .duration{
	font-size: 12px;
  color: #ec3a71;
  font-weight: 900;
}

.audio-detail .premium{
	background: #ec3a71;
  border-radius: 50%;
  font-size: 10px;
  padding: 2px;
  height: 20px;
    width: 20px;
    /* float: right; */
    display: inline-block;
    margin-top: 10px;
    margin-right: 5px;
}

.audio-detail .premium img{
	width: 16px !important;
	height: 16px !important;
	display: inline-block;
}

.loader{
	position: absolute;
    text-align: center;
    top: 0;
    position: fixed;
    /* / text-align: center; */
    width: 100%;
    height: 100%;
    background: #222939;
    vertical-align: middle;
    padding-top: 20%;
    display: table-cell;
		z-index: 9999;
		/*display: none;*/
}

@media screen and (max-width: 720px) {
  .home-slider-content{
	  display: none !important;
  }

  .preference-box {
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 15px;
    padding-left: 15px;
  }
}

.home-slider{
	margin-top: 72px !important;
}

.home-slider .slick-slide {
	cursor:pointer;
}

.home-slider-content{
	position: absolute;
	width: 1171px;
	height: 100%;
	/*background-image: linear-gradient(to right, rgba(1,1,1,1) -30%, rgba(0,0,0,0) 10%);*/
	background-image:linear-gradient(to right, rgba(1,1,1,0.9), rgba(1,1,1,0.7), rgba(1,1,1,0.5), rgba(1,1,1,0.3), rgba(1,1,1,0.09), rgba(1,1,1,0.07), rgba(1,1,1,0.05), rgba(1,1,1,0.03), rgba(1,1,1,0.01), rgba(1,1,1,0), rgba(1,1,1,0.0), rgba(1,1,1,0.0), rgba(1,1,1,0.0));
	z-index: 99999;
	top: 0;
	color: #fff;
	padding: 20px;
	max-height: 363px;
	border-radius: 5px;
}

.home-slider-description{
	bottom: 0;
	position: absolute;
	max-width: 300px;
}

.home-slider-description p {
	font-size: .8rem;
}

.home-slider-description img.play{
	width: 40px;
	border: 2px solid #ec3a71;
	border-radius: 50% !important;
	padding: 7px;
}

.artist .slick-slide{
  border-radius: 50%;
	overflow: hidden;
	height: 120px;
  width: 120px;
}

.artist .slick-slide img{
	border-radius:50% !important;
	width: 120px !important;
}


/* .img-loader img{
	width: 140px !important;
} */
.img-loader {
	width: 100%;
    display: inline-block;
}

@media only screen and (min-width: 920px){
	.zoom:hover .slick-list{
		overflow: visible;
	}
	
	.zoom .slick-slide{
		transition: transform .3s, z-index 0.3s; 
		transform: translate3d(0,0,0);
		z-index: 0;
		position: relative;
	}

	.zoom .slick-slide:hover{
		transform: scale(1.5);
		z-index:999;
		position:relative;
		transition-delay: .3s;
	}
}

.slick-next {
    right: 6px;
    top: -20px;
}

.slick-prev {
    left: 94%;
    top: -20px;
}



.btn {
	display: inline-block;
	font-weight: 400;
	color: #7e807f ;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	/* background-color: transparent; */
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: .25rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	font-weight: 600;
}
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 10rem;
	padding: .5rem 0;
	margin: 0.125rem 99px 0;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	list-style: none;
	background-color: #0f0f0d!important;
	background-clip: padding-box;
	border: 1px solid rgba(0,0,0,.15);
	border-radius: .25rem;
}
.dropdown{
	padding: 6px;
}
.dropdown-toggl{
	text-transform: uppercase;
	font-size: 0.8rem;
	font-weight: 700;
	letter-spacing: 0.1rem;
	padding: 9px 0px;
}
.btn:focus{
	border: none;
	outline: none;
	box-shadow: none;
}
.season-btn{
background-color: #ee0979 !important;
	border-color: #ee0979 !important;
	color: #fff !important;
	padding: 3px 23px;
	font-size: 14px;
	font-weight: 400;
}
.season-btn:focus{
	box-shadow: none!important;
	outline: none!important;
}
.season-dropdown{
	margin: 0.125rem 2px 0!important;
	text-align: center;
}
.list-heart{
	color:#fff;
	padding-top: 8px;
}
.list-heart img{
	width:22px;

}
.list-heart a{
color:#fff;

}
.list-heart a:hover{
		border:none!important;
		text-decoration: none;
	
	}
	.right-col {
    padding: 0 !important;
    display: inline;
    width: 100%;
    /* margin-left: 52px; */
    /* background: red; */
    /*margin-top: -256px;*/
    /*background-image: linear-gradient(#2229395e 5%, #222939 95%);*/
}
/* .top-background{
	/*background: #2229395e;
	width: 100%;
	height: 361px;
	position: absolute;
	top: 0;
} */
.fff{
	color:#fff!important;
}
.list-details td{
	border:none!important;
}
.list-details{
	border-bottom: 1px solid #44464a;
}

/* #jp_container_1{
	display: none;
} */

.navbar-custom {
    background-color: #111215 !important;
}

.variable-new .slick-slide{
	width: 150px;
}

/*Slider updates*/
.slick-slide img {
    border-radius: 5px !important;
}


/* .recommended .slick-track, .variable .slick-track, .variable-new .slick-track{
	/*transform: translate3d(0px, 0px, 0px) !important;
} */


/* .img-box {
    /*-webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    cursor: pointer;
    perspective: 1000px;
    position: relative;
    text-align: center;
    transition: all 2s linear;
} */

@keyframes asloader{0%{background-position:-200px 0}to{background-position:400px 0}}

.slick-slide .img-loader{
	border-radius: 5px;
}

/* .img-loader {
		/*background: #f6f7f8 no-repeat 150px 180px;
    animation: asloader 1.5s forwards linear;
    animation-iteration-count: 10;
    backface-visibility: hidden;
    background: #f6f7f8 no-repeat 150px 180px;
    background-image: linear-gradient(90deg,#f6f7f8 0,#edeef1 20%,#f6f7f8 40%,#f6f7f8);
    background-size: 100%;*/
    /*perspective: 1000px;*/
    /*transform: translateZ(0);*/
    /*transition: all 1s linear;*/
    /*will-change: transform;*
} */

/*User login bar*/
.user-login{
	margin-right: 60px;
}

.user-login a{
	text-decoration: none;
}

.user-login .dropdown {
    padding: 4px;
}

.user-login .profile-image{
    width: 24px;
    margin-right: 3px;
}

.user-login .dropdown-toggle::after{
	display: none;
}

.user-login .dropdown-menu{
	  margin: 0;
    padding: 0;
    left: 0px;
    background-color: #111215  !important;
    top: 40px;
    width: 100px;
    min-width: 10rem;
}

#userProfileBlock a{
	color: #fff;
	font-size: 0.8rem;
	padding: 17px 0px;
}

#btnUserLogin{
	/*color: #ffffff;
  cursor: pointer;
  padding: 10px;
	font-weight: 700;*/
	color: #ffffff !important;
    cursor: pointer;
    padding: 10px;
    font-weight: 700;
    background: #ec3a71;
    padding: 0px 26px;
    border-radius: 3px;
    height: 35px;
}

.user-login .dropdown-menu .nav-item{
	color: rgba(255,255,255,0.5);
	width: 100% !important;
  text-align: left !important;
}

.user-login li{
	display: inline-flex;
}

.user-login .dropdown-menu .nav-item:hover .nav-link{
	background: #191f2b !important;
}

.user-login .dropdown-menu .nav-item .nav-link {
  display: block;
	padding: 0.5rem 1rem !important;
	text-transform: capitalize;
	width: 100%;
}

.user-login .dropdown-menu .nav-item .nav-link img {
	width: 18px;
	margin-right: 10px;
}

.user-login .dropdown:hover .dropdown-menu{
	display: block;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
		padding-left: 1.5rem;
		color: #ffffff;
}

.navbar-nav .nav-item:hover .nav-link{
	color: #ec3a71;
}

/*Slider media queries*/
@media only screen and (max-width:320px){
	.center .slick-slide{
		width: 240px;
	}
}

@media only screen and (min-width: 321px) and (max-width:400px){
	
	.center .slick-slide{
		width: 280px;
	}

	.home-placeholder .placeholder-slide{ 
		height: 140px !important;
		width: 280px !important;
	}
}
@media only screen and (min-width: 401px) and (max-width:480px){
	
	.center .slick-slide{
		width: 320px;
	}

	.home-placeholder .placeholder-slide{ 
		height: 160px !important;
		width: 320px !important;
	}

}

@media only screen and (min-width: 481px) and (max-width:540px){
	
	.center .slick-slide{
		width: 420px;
	}

	.home-placeholder .placeholder-slide{ 
		height: 210px !important;
		width: 420px !important;
	}
}
@media only screen and (min-width: 541px) and (max-width:720px){
	
	.center .slick-slide{
		width: 480px;
	}

	.home-placeholder .placeholder-slide{ 
		height: 240px !important;
		width: 480px !important;
	}

}


/*Login modal*/

#loginModal {
	padding-right: 0 !important;
}

#loginModal .modal-body{
	background: #191f2b ;
	color: #ffffff;
	border-radius: 5px;
}


#loginModal .modal-dialog{
	max-width: 400px;
	pointer-events: auto;
	margin: auto;
	margin-top: 50px;
}

#loginModal .modal-dialog p{
	padding: 10px 0;
	margin: 0;
	font-size: .9rem;
}

#loginModal .modal-dialog p.terms{
	font-size: 11px;
	font-style: italic;
}

#loginModal .close{
	z-index: 9999;
  position: absolute;
  color: #ffffff;
  box-shadow: none;
  right: -17px;
  top: -15px;
  background: #ec3a71;
  /* padding: 5px; */
  border-radius: 50%;
  margin: 0;
  height: 40px;
	width: 40px;
	opacity: 1;
	outline: none;
		
}

#loginModal .close:hover{
	cursor: pointer;
}

@media only screen and (max-width:720px){
	#loginModal .modal-dialog{
		width: 85%;
	}
	.modal.show .modal-dialog{
		width: 85%;
		margin: 50px auto;
	}
}

/*Login modal end*/



/*App Button Modal */

#appButtonModal {
	padding-right: 0 !important;
}

#appButtonModal .modal-body{
	background: #191f2b ;
	color: #ffffff;
	border-radius: 5px;
}


#appButtonModal .modal-dialog{
	max-width: 400px;
	pointer-events: auto;
	margin: auto;
	margin-top: 50px;
}

#appButtonModal .modal-dialog p{
	padding: 10px 0;
	margin: 0;
	font-size: .9rem;
}

#appButtonModal .modal-dialog p.terms{
	font-size: 11px;
	font-style: italic;
}

#appButtonModal .close{
	z-index: 9999;
  position: absolute;
  color: #ffffff;
  box-shadow: none;
  right: -17px;
  top: -15px;
  background: #ec3a71;
  /* padding: 5px; */
  border-radius: 50%;
  margin: 0;
  height: 40px;
	width: 40px;
	opacity: 1;
	outline: none;
		
}

#appButtonModal .close:hover{
	cursor: pointer;
}

@media only screen and (max-width:720px){
	#appButtonModal .modal-dialog{
		width: 85%;
	}
}

/*App Button modal end*/

#blockLastPlayed{
	display: none;
}

.navbar-toggler {
    border: none;
}

#userProfileBlock, #loginBlock{
	display: none;
}

.btn-social{
	padding: 0 !important;
}

.btn-social img{
	margin-bottom: 8px;
}

#category-content .media .audio img{
	border-radius: 5px;
	height: 180px;
    /* width: 150px; */
    width: 140px;
}

#category-content .media .audio .audio-detail{
 bottom: 0 !important;
}

/*Series*/

@media only screen and (min-width:1367px){
	.series-container .container{
		max-width: 1600px !important;
	}

	.container{
		max-width: 1140px !important;
	}
}


.series-container{
	color: #ffffff;
  height: 600px;
  /*background: url(../img/series-banner.jpg) no-repeat right;*/
  background-size: contain;
}

.series-container .series-cover{
	width: 100%;
	height: inherit;
	/*background: rgba(34,41,57,1);*/
	background-image: linear-gradient(90deg, #000000, transparent) !important;
}

/* .series-container .series-cover img{
	/*max-width: 1171px;
} */

.series-container .series-title{
	font-size: 40px;
	padding-bottom: 20px;
}

.series-container .series-details{
	position: absolute;
  top: 0;
  /*padding-left: 50px;*/
  /*background-image: linear-gradient(to right, rgba(34,41,57,1) 30%, rgba(0,0,0,0));*/
  height: 600px;
	padding-top: 130px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.series-container .series-desc{
	padding-top: 16px;
}

.series-container .meta-info{
	padding-top: 20px;
}

.series-container .meta-info dl, .series-container .meta-audience dl{
	display: table-row;
}

.series-container .meta-info dt{
	display: table-cell;
}

.series-container .meta-info dd{
	padding-left: 10px;
}

.series-container .meta-audience{
	margin-top: 20px;
}

.series-container .meta-audience dd{
	display: table-cell;
	padding-right: 20px;
}

.series-container .meta-audience dd img{
	width: 22px;
}

.btnLikeMedia{
	background: transparent;
	border: none;
  outline: none;
  outline: none;
  padding: 0;
}

/*Mobile Menu*/
/* @Media screen and (min-width:721px){
	.header-mobile{
		display: none !important;
	}
} */
@Media screen and (min-width:993px){
	.header-mobile{
		display: none !important;
	}
}
@Media screen and (max-width:993px){
	.header-mobile{
		display: block;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	}
	.row{
		margin: auto;
		width: 100%;
	}
	.navbar-toggler{
		margin-top: 7px;
	}
}


@Media screen and (max-width:720px){

	.social-share.whatsapp {
		display: initial;
	}

	.preferences h2.heading, .category h2.heading {
    margin-top: 80px;
    font-size: 34px;
	}

	.col-md-2{
		width: 50%;
	}

	.col-sm-6 {
		width: 50% !important;
	}

	.navbar-toggler{
		margin-top: 7px;
	}
	
	.header-desktop{
		display: none !important;
	}

	/* .header-mobile{
		display: block;
	}

	.header-mobile .row{
		width: 100%;
		margin-left: 0;
		margin-right: 0;
	} */

	.navbar-brand.logo{
		text-align: center;
		width: auto !important;
		padding: 10px 0;
	}

	.navbar-brand.logo .img-logo{
		margin-left: 0px;
	}

	.loginBtnWrapper{
		text-align: center;
	}

	#btnUserLogin {
    color: #ffffff !important;
    cursor: pointer;
    background: #ec3a71;
    border-radius: 3px;
    height: 30px;
	}

	.user-login {
		margin-right: 0;
		text-align: right;
		margin-top: 12px;
	}

	.col-4{
		width: 33.33%;
	}

	.sidenav .user-details{
		padding: 30px 10px;
    border-bottom: 1px solid #1b1b1b;
		color: #FFFFFF;
		background: #16171b;
	}

	#mobile_menu a, .profile-links{
		border-bottom: 1px solid #1b1b1b;
	}

	.series-container {
		height: auto !important;
	}

	.series-container .series-cover{
		display: none !important;
	}

	.series-container .series-details {
		position: inherit !important;
		top: 0 !important;
		padding-left: 15px !important;
		background-image: linear-gradient(90deg, #000000, transparent) !important;
		height: auto !important;
		padding-top: 70px !important;
		left: 0 !important;
		right: 0 !important;
		margin-left: auto !important;
		margin-right: auto !important;
	}

	.media-play-share {
		padding-bottom: 20px;
		text-align: center;
	}

	.btn-play {
		margin-bottom: 15px;
	}
}

.sidenav{background-color:#111215;height:100%;left:0;overflow-x:hidden;position:fixed;top:0;transition:.5s;width:0;z-index:9999;}
.sidenav a{color:#ffffff;display:block;font-size:1em;padding:8px 8px 8px 32px;text-decoration:none;transition:.3s;}
.sidenav a:hover,.offcanvas a:focus{color:#f1f1f1;}

.sidenav .closebtn{    
	font-size: 36px;
  margin-left: 0px;
  position: absolute;
  right: 6px;
	top: -15px;
}

@media screen and (max-height:450px) {
.sidenav{padding-top:15px;}
.sidenav a{font-size:18px;}
}

.sidenav .user-details .profile-image, .sidenav .user-details .user-info{
	text-align: center;
}

.sidenav .user-details .profile-image img{
	width: 55px;
}



/** Placeholder CSS*/

@media screen and (max-width:720px){
	.home-slider-placeholder{
		-webkit-animation-duration: 3s !important;
  	animation-duration: 3s !important;
		-webkit-background-size: 800px 104px !important;
 		background-size: 800px 104px !important;
	}

}

.home-placeholder .placeholder-slide{ 
	height: 363px;
	width: 1171px;
}
.fixed-header{

	position: sticky;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes placeHolderHome {
  0% {
    background-position: -1171px 0;
  }
  100% {
    background-position: 1171px 0;
  }
}

@keyframes placeHolderHome {
  0% {
    background-position: -1171px 0;
  }
  100% {
    background-position: 1171px 0;
  }
}

.no-placeholder{
	background: #1a2233;
}

.home-slider-placeholder {
  display: inline-block;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderHome;
  animation-name: placeHolderHome;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-gradient(linear, left top, right top, color-stop(18%, #1a2233), color-stop(30%, #222939), color-stop(33%, #1a2233));
  background: -webkit-linear-gradient(left, #1a2233 18%, #222939 30%, #1a2233 33%);
  background: linear-gradient(to right, #1a2233 8%, #222939 30%, #1a2233 50%);
  -webkit-background-size: 2200px 104px;
  background-size: 2200px 104px;
  height: inherit;
  position: relative;
}

.content-placeholder {
  display: inline-block;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #1a2233), color-stop(18%, #222939), color-stop(33%, #1a2233));
  background: -webkit-linear-gradient(left, #1a2233 8%, #222939 18%, #1a2233 33%);
  background: linear-gradient(to right, #1a2233 8%, #222939 18%, #1a2233 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  height: inherit;
  position: relative;
}

.content-placeholder-bottom {
  display: inline-block;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #1a2233), color-stop(18%, #222939), color-stop(33%, #1a2233));
  background: -webkit-linear-gradient(left, #1a2233 8%, #222939 18%, #1a2233 33%);
  background: linear-gradient(to right, #1a2233 8%, #222939 18%, #1a2233 33%);
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  height: inherit;
  position: relative;
}

.m-t-20 {
	margin-top: 20px;
}

.m-t-4{
  margin-top: 4px;
}

.full-height {
	height: 100%;
}

.m-b-2 {
	margin-bottom: 2px;
}

.left-round-image {
   float: left;
   border-radius: 50%;
}

.content-placeholder.contact-image{
	height:35px;
	width:35px;
}

.content-placeholder.title{
	width:90%;
	height:10px;
}

.content-placeholder.date{
	width:36%;
	height:10px;
}

.m-t-10{
	margin-top:10px;
}

/* Single Audio */
.content-placeholder.single{
	height:180px; 
	width:140px; 
	border-radius:5px;
}

.content-placeholder.single .desc{
	height:50px;
	position:absolute; 
	bottom: 0;
}

/* Artist */
.content-placeholder.artist{
	height:120px; 
	width:120px; 
	border-radius:50%;
}

/** Placeholder CSS End*/


/* Mobile Single Audio Screen */
#fsModal.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

#fsModal .modal-dialog {
	position: fixed;
    margin: auto;
    width: 400px;
    height: 80%;
    max-width: 100%;
    padding: 0;
	top: 10%;
	left: -50%;
    right: -50%;
}

#fsModal .modal-content {
  background: #222939;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  box-shadow: none;
}

#fsModal .modal-header {
  /*position: absolute;*/
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  padding: 0px;
  border: 0;
}

#fsModal .modal-title {
  font-weight: 300;
  font-size: 2em;
  color: #fff;
  line-height: 30px;
}

#fsModal .modal-body {
	color: #fff;
  /*position: absolute;*/
  padding-top: 50px;
  padding-bottom: 60px;
  width: 100%;
  font-weight: 300;
	overflow: auto;
}

#fsModal .modal-body .audio-image{
	text-align: center;
}

#fsModal .modal-body .audio-image img{
	width: 250px;
	box-shadow: 0px 0px 20px #47494d;
  border-radius: 5px;
}

#fsModal .modal-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60px;
  padding: 10px;
  background: #f1f3f5;
}

.series .slick-slide{
    width: 200px;
    height: 246px;
}

.width-200 .slick-slide{
	width: 200px !important;
    height: 246px !important;
}

.slick-dots li.slick-active button::before{
	color:#ec3a71 !important;
}

.width-150 .slick-slide{
	width: 158px !important;
	/* margin: 0px 10px; */
}

a{
	cursor:pointer;
}

.audio-block{
	overflow:hidden !important;
}

#artist{
	margin-bottom:70px;
}

.social-share {
	margin-right: 5px;
}

.social-share img {
	width: 35px;
	cursor: pointer;
}

.media-play-share {
	margin-top: 20px;
}