.footer-main {
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .footer-main {
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-items {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;
    }

    .footer-items a:first-child {
        margin-left: 1em !important;
    }
}