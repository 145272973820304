/*!
 * Start Bootstrap - One Page Wonder v5.0.3 (https://startbootstrap.com/template-overviews/one-page-wonder)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-one-page-wonder/blob/master/LICENSE)
 */

 body {
  font-family: 'Lato';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Catamaran';
  font-weight: 800 !important;
}

.btn-xl {
  text-transform: uppercase;
  padding: 1.5rem 3rem;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.bg-black {
  background-color: #000 !important;
}

.rounded-pill {
  border-radius: 5rem;
}

.navbar-custom {
  background-color: rgba(0, 0, 0, 0.7);
}

.navbar-custom .navbar-brand {
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
}

.navbar-custom .navbar-nav .nav-item .nav-link {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

header.masthead {
  position: relative;
  overflow: hidden;
  padding-top: calc(7rem + 72px);
  padding-bottom: 7rem;
  background: -webkit-gradient(linear, left bottom, left top, from(#ff6a00), to(#ee0979));
  background: linear-gradient(0deg, #ff6a00 0%, #ee0979 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

header.masthead .masthead-content {
  z-index: 1;
  position: relative;
}

header.masthead .masthead-content .masthead-heading {
  font-size: 4rem;
}

header.masthead .masthead-content .masthead-subheading {
  font-size: 2rem;
}

header.masthead .bg-circle {
  z-index: 0;
  position: absolute;
  border-radius: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#ee0979), to(#ff6a00));
  background: linear-gradient(0deg, #ee0979 0%, #ff6a00 100%);
}

header.masthead .bg-circle-1 {
  height: 90rem;
  width: 90rem;
  bottom: -55rem;
  left: -55rem;
}

header.masthead .bg-circle-2 {
  height: 50rem;
  width: 50rem;
  top: -25rem;
  right: -25rem;
}

header.masthead .bg-circle-3 {
  height: 20rem;
  width: 20rem;
  bottom: -10rem;
  right: 5%;
}

header.masthead .bg-circle-4 {
  height: 30rem;
  width: 30rem;
  top: -5rem;
  right: 35%;
}

@media (min-width: 992px) {
  header.masthead {
    padding-top: calc(10rem + 55px);
    padding-bottom: 10rem;
  }
  header.masthead .masthead-content .masthead-heading {
    font-size: 6rem;
  }
  header.masthead .masthead-content .masthead-subheading {
    font-size: 4rem;
  }
}

.bg-primary {
  background-color: #ee0979 !important;
}

.btn-primary {
  background-color: #ee0979;
  border-color: #ee0979;
}

.btn-primary:active, .btn-primary:focus, .btn-primary:hover {
  background-color: #bd0760 !important;
  border-color: #bd0760 !important;
}

.btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(238, 9, 121, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(238, 9, 121, 0.5);
}

.btn-secondary {
  background-color: #ff6a00;
  border-color: #ff6a00;
}

.btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover {
  background-color: #cc5500 !important;
  border-color: #cc5500 !important;
}

.btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 106, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 106, 0, 0.5);
}


* {
  box-sizing: border-box;
}




.jp-audio {
  width: 100%;
  position: fixed;
  bottom: -1px;
  z-index: 99;
}

.jp-audio .jp-progress {
  height: 3px;
}

.jp-progress {
  background-color: transparent;
}

.jp-details {
  width: 30%;
  position: absolute;
  background: transparent;
  display: block !important;
  margin-top: 15px;
  margin-left: 5%;
}

.jp-details .jp-title {
  color: #ffffff;
  font-weight: 600;
  padding: 0px 10px 0 20px;
  font-size: .86em;
}

.jp-details .jp-author {
  color: #ffffff;
}

.jp-playlist {
  display: none;
}

#jpPoster img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

#jpArtist {
  font-weight: 400;
  font-size: .72em;
}

.jp-audio,
.jp-audio-stream,
.jp-video {
  font-size: 16px;
  font-family: inherit;
  line-height: 1.6;
  color: #666;
  border: none;
  background-color: #111215;
}

.jp-audio .jp-type-playlist .jp-progress {
  left: 0;
  width: 100%;
  position: absolute;
  top: 0;
}

.jp-play-bar {
  background: #eb3a70;
  width: 0;
  height: 3px;
}

.jp-volume-bar-value {
  background: #eb3a70;
  width: 0;
  height: 5px;
}

.jp-volume-controls {
  position: absolute;
  top: 24px;
  right: 0;
  left: unset;
  width: 200px;
}

.jp-seek-bar {
  background: #191f2b;
  width: 0;
  height: 100%;
  cursor: pointer;
}

.jp-interface {
  position: relative;
  background-color: #111215;
  width: 100%;
}

/* .jp-audio .jp-type-playlist .jp-time-holder {
  /*display: none;
} */

.jp-audio .jp-interface,
.jp-audio-stream .jp-interface {
  height: 58px;
}

.jp-toggles {
  display: none;
}

.jp-mute,
.jp-stop {
  display: none !important;
}

.jp-volume-max,
.jp-volume-max:focus {
  left: 0px;
  background: url(../img/icons/volume-small.png) no-repeat
}

.jp-audio .jp-controls {
  width: 300px;
  padding: 20px 18px 0;
}

.jp-controls {
  margin: auto !important;
}

.jp-current-time,
.jp-duration {
  min-width: 30px;
}

.jp-audio .jp-time-holder {
  position: absolute;
  top: 22px;
  right: 218px;
  left: unset !important;
}

.jp-current-time {
  color: #ec3a71 !important;
  font-weight: 800;
}

.jp-current-time,
.jp-duration {
  width: auto !important;
}

.jp-current-time,
.jp-duration,
.jp-time-divider {
  font-size: .75em;
  font-style: oblique;
  color: #fff;
  padding: 0 2px;
}

.jp-audio .jp-type-playlist .jp-time-holder {
  width: auto !important;
  max-width: 100px !important;
  right: 15px !important;
}

.jp-time-divider {
  float: left;
  display: inline;
}

.jp-next,
.jp-previous,
.jp-stop,
.jp-forward,
.jp-backward {
  width: 28px;
  height: 28px;
  margin-top: 3px;
}

/* .img-loader {
  /* perspective: 1000px; */
  /* transform: translateZ(0); */
  /* transition: all 1s linear; */
  /* will-change: transform; */
  /* margin: 0 9.5px; 
} */

.jp-play {
  background: url(../img/icons/play-button-white.png) no-repeat;
  margin-left: 22px;
}

.jp-play:focus {
  background: url(../img/icons/play-button-white.png) no-repeat;
}

.jp-state-playing .jp-play:focus {
  background: url(../img/icons/pause.png) no-repeat;
}
.rhap_container {
  position: fixed;
  bottom: 0;
  left: 0;
}

.jp-state-playing .jp-play {
  background: url(../img/icons/pause.png) no-repeat;
  margin-left: 22px;
}

.jp-previous,
.jp-previous:focus {
  background: url(../img/icons/backward-white.png) no-repeat;
}

.jp-next,
.jp-next:focus {
  background: url(../img/icons/forward-white.png) no-repeat;
  margin-left: 30px;
}

.jp-forward {
  background: url(../img/icons/seek-right-small.png) no-repeat;
  margin-left: 13px;
}

.jp-backward {
  background: url(../img/icons/seek-left-small.png) no-repeat;
  margin-left: 30px;
}
@media screen and (max-width: 992px) {
  .navbar--header--desktop{
  display: none !important;
  }
}


/* custom media player css  */

@media screen and (max-width: 592px) {
  .thumnail--img--playing{

    display: none;
  }
  .thumnail--img--stop{
    display: none;
  }
  .play--pause--img{
    height: 18px !important;
  }
  .forward--backward--img{
    display: none;
  }
  .forward--backward--btn{
    display: none;
  }
  .slick-prev{
    left: 100% !important;
  }
  .custom--media--duration--timeline{
    min-width: auto !important;
    margin-right: 0 !important;
  }
  .width-150 .slick-slide{
    width: auto !important;
  }
}
.custom--media--player{
  position: fixed;
  bottom: -1px;
  left: 0;
  background-color: #111215;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.play--pause--img{
  height: 18px;
}
.forward--backward--img{
  height: 16px;
}
.custom--seek--input{
  width: 100%;
  height: 3px;
  position: absolute;;
}
.main--customization{
  /* margin-top: 18px;
  margin-bottom: 18px; */
height: 56px;
align-items: center;
}
.custom--media--seek--length input[type="range"], .custom--media--seek--volume input[type="range"]{
  /* -webkit-appearance: none; */
  filter: hue-rotate(116deg);
  cursor: pointer;
}
.custom--media--seek--volume input[type="range"]{
  width: 46px;
}
.custom--media--duration--timeline{
  min-width: 90px;
  text-align: right;
  margin-right: 12px;
}

.custom--media--seek--length input[type="range"]::-webkit-slider-thumb {
  /* -webkit-appearance: none; */
  /* filter: hue-rotate(116deg); */
  background-color: #ec3a71;
  height: 3px;
  width: 3px;
}
.custom--player--progress{
  color: #ec3a71;
  font-weight: bolder;
  font-size: 14px;
}
.custom--player--duration{
  color: #fff;
  font-size: 14px;
}
.main--customization button{
  background-color: transparent;
  border: none;
  outline: none;
  width: 4rem;
}
.thumnail--img--playing{
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  object-fit: cover;
  -webkit-animation: rotation 2s infinite linear;
}
.thumnail--img--stop{
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
}

100% {
    -webkit-transform: rotate(359deg);
}
}
.player--audio--title{
  color: #ffffff;
  font-weight: 600;
  padding: 0px 10px 0 20px;
  font-size: .86em;
}
.player--audio--type{
  font-weight: 400;
  font-size: .72em;
  color: #666;
}
.close--download{
  z-index: 9999;
  position: absolute;
  color: #ffffff;
  box-shadow: none;
  right: -17px;
  top: -15px;
  background: #ec3a71;
  /* padding: 5px; */
  border-radius: 50%;
  margin: 0;
  height: 40px;
  width: 40px;
  opacity: 1;
  outline: none;
  font-weight: bolder;
  font-size: 24px;
  border: none;
  outline: none;
}
/* @media only screen and (min-width: 920px){
	.zoom:hover .slick-list{
		overflow: visible;
	}
	
	.zoom .slick-slide{
		transition: transform .3s, z-index 0.3s; 
		transform: translate3d(0,0,0);
		z-index: 0;
		position: relative;
	}

	.zoom .slick-slide:hover{
		transform: scale(1.5);
		z-index:999;
		position:relative;
		transition-delay: .3s;
	}
} */
.main--thumbnail--media img{
  width: 140px !important;
}

@media only screen and (min-width: 920px){
	.main--thumbnail--media:hover .slick-list{
		overflow: visible;
	}
	
  .main--thumbnail--media .slick-slide{
		transition: transform .3s, z-index 0.3s; 
		transform: translate3d(0,0,0);
		z-index: 0;
		position: relative;
	}

	.main--thumbnail--media .slick-slide:hover{
		transform: scale(1.5);
		z-index:999;
		position:relative;
		transition-delay: .3s;
	}
}