.swiper {
    width: 100%;
    height: 362px;
    margin-top: 25px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: black; */
    color: #fff;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer;
    background: #ec3a71 !important;
}

/* .hello{
  width: 100% !important;
} */
/* .hello img{
  width: 100% !important;
} */
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 14px;
    color: rgba(182, 177, 177, 0.897);
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 14px;
    color: rgba(182, 177, 177, 0.897);
}
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }
  